// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-commission-form-js": () => import("./../../../src/pages/commission-form.js" /* webpackChunkName: "component---src-pages-commission-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-offer-js": () => import("./../../../src/pages/make-offer.js" /* webpackChunkName: "component---src-pages-make-offer-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-support-form-js": () => import("./../../../src/pages/support-form.js" /* webpackChunkName: "component---src-pages-support-form-js" */),
  "component---src-templates-archive-item-js": () => import("./../../../src/templates/archive-item.js" /* webpackChunkName: "component---src-templates-archive-item-js" */),
  "component---src-templates-art-pice-js": () => import("./../../../src/templates/art-pice.js" /* webpackChunkName: "component---src-templates-art-pice-js" */),
  "component---src-templates-categorys-js": () => import("./../../../src/templates/categorys.js" /* webpackChunkName: "component---src-templates-categorys-js" */),
  "component---src-templates-mediums-js": () => import("./../../../src/templates/mediums.js" /* webpackChunkName: "component---src-templates-mediums-js" */)
}

